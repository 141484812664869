import React, { useEffect, useState, useMemo  } from "react";
import ProjetosApiService from "../../../../services/api/ProjetosApiService";
import { Form, Button } from "react-bootstrap";
import Select from 'react-select';
import MonthYearPicker from "../../../../components/MonthYearPicker";
import 'react-datepicker/dist/react-datepicker.css';
import ClienteoApiService from "../../../../services/api/CadastroClientesService";
import makeAnimated from 'react-select/animated';
import useAlertNotification from '../../../../context/AlertNotificationContext';

const animatedComponents = makeAnimated();

const FiltroHistoricoCusto = ({clientes, projetos, handleDadosProjetosHistorico}) => {
    //States
    const [clienteSelecionado, setClienteSelecionado] = useState("");
    const [projetosSelecionados, setProjetosSelecionados] = useState([]);
    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());
    const [selectedOptionRadio, setSelectedOptionRadio] = useState('default');
    const [tipoCusto, setTipoCusto] = useState("");
    const [categories, setCategories] = useState([]);
    const [categoriesSelect, setCategoriesSelect] = useState("");
    const [projetoFiltradoCategoria, setProjetoFiltradoCategoria] = useState(projetos);
    const [projetoFiltradoCategoriaCliente, setProjetoFiltradoCategoriaCliente] = useState([]);
    const [todasCategorias, setTodasCategorias] = useState(false);
    const [categoriasChave, setCategoriasChave] = useState({});
    const [todosProjetosCategorias, setTodosProjetosCategorias] = useState(false)

    //Effects
    useEffect(async () => {
        if (clienteSelecionado && clienteSelecionado !== '--Selecione o cliente--') {
            const cliente = clientes.find(c => c.nome === clienteSelecionado);
            try {
                const resp = await ClienteoApiService.getClientProjectsById(cliente.id);
                const projetosCliente = resp.data.projetos;

                const projetosFiltrados = projetosCliente.map(item => ({
                    key: item.chave,
                    name: item.nome || item.chave,
                    category: categoriasChave[item.chave]
                }));
                setProjetoFiltradoCategoriaCliente(projetosFiltrados);
            }
            catch(err) {
                console.error("Erro em getClientProjectsById: ", err)
            }
        } else {
            setProjetosSelecionados([]);
            setProjetoFiltradoCategoriaCliente([]);
        }
    }, [clienteSelecionado, clientes]);

    useEffect(async () => {
        adicionarCategoria(projetos)
        try {
            const respCategorias = await ProjetosApiService.getCategories();
            setCategories(respCategorias.data.data)
        } catch (error) {
            console.error("Erro ao buscar categorias")
        }
    }, []);

    useEffect(async () => {
        if(categoriesSelect.length > 0) {
            
            const projetoFiltrado = projetos.filter(projeto =>
                categoriesSelect.some(category => category.value === projeto.category)
            );
            setProjetoFiltradoCategoria(projetoFiltrado);
        } else {
            setProjetoFiltradoCategoria(projetos)
        }
        if(todasCategorias){
            setTodosProjetosCategorias(false)
            setProjetosSelecionados([])
        }
    }, [categoriesSelect]);

    const memoizedProjetosOptions = useMemo(() => {
        return projetoFiltradoCategoria
            .filter(projeto => 
                categoriesSelect.length > 0 ? categoriesSelect.includes(projeto.category) : true
            )
            .map(projeto => ({
                value: projeto.key,
                label: `${projeto.key} - ${projeto.name}`,
                categoria: projeto.category,
            }));
    }, [categoriesSelect, projetoFiltradoCategoria]);

    const memoizedProjetosClienteOptions = useMemo(() => {
        if (categoriesSelect.length > 0) {
            const projetosFiltrados = projetoFiltradoCategoriaCliente.filter(projeto => 
                categoriesSelect.some(categoria => categoria.value === projeto.category)
            );
        
            const arrayDeOpcoesCategorias = [
                ...(projetosFiltrados.length > 0 ? [{ value: "TODOS", label: 'TODOS OS PROJETOS' }] : []),
                ...projetosFiltrados.map(projeto => ({
                    value: projeto.key,
                    label: `${projeto.key} - ${projeto.name} - ${projeto.category}`,
                    categoria: projeto.category,
                }))
            ];
        
            return arrayDeOpcoesCategorias;
        }
        const arrayDeOpcoes = [
                            { value: "TODOS", label: 'TODOS OS PROJETOS' },
                            ...projetoFiltradoCategoriaCliente.map(projeto => ({
                                value: projeto.key,
                                label: `${projeto.key} - ${projeto.name}`,
                                categoria: projeto.category,
                            }))
                           ]

        return arrayDeOpcoes;
    }, [categoriesSelect, projetoFiltradoCategoriaCliente]);

    //Funções handle
    const handleClienteChange = (e) => {
        setProjetosSelecionados("")
        const selectedValue = e.target.value;
        if (selectedValue === '--Selecione o cliente--') {
            setClienteSelecionado('');
        } else {
            setClienteSelecionado(selectedValue);
        }
    };

    const handleProjetosClienteChange = (selectedOptions) => {
        const todosOptionSelected = selectedOptions.some(option => option.value === 'TODOS');

        if (todosOptionSelected) {
            setTodosProjetosCategorias(true);
            
            setTodasCategorias(true);
            const filteredOptions = memoizedProjetosClienteOptions.filter(option => option.value !== 'TODOS');
            setProjetosSelecionados(filteredOptions);
            
        } else {
            setTodosProjetosCategorias(false);
            setTodasCategorias(false);
            setProjetosSelecionados(selectedOptions);
        }
    };

    const handleProjetosChange = (selectedOptions) => {
        const todosOptionSelected = selectedOptions.some(option => option.value === 'TODOS');
    
        if (todosOptionSelected) {
            setTodosProjetosCategorias(true);
            if (categoriesSelect.length === 0) {
                setProjetosSelecionados([{ value: "TODOS", label: "TODOS OS PROJETOS" }]);
            } else {
                setTodasCategorias(true);
                setProjetosSelecionados(
                    optionsProjetos.filter(option => option.value !== 'TODOS')
                );
            }
        } else {
            setTodosProjetosCategorias(false);
            setTodasCategorias(false);
            setProjetosSelecionados(selectedOptions);
        }
    };

    const handleCategoriaChange = (selectedOption) => {
        setCategoriesSelect(selectedOption);
    };

    const handleRadioChange = (event) => {
        setSelectedOptionRadio(event.target.value);
        setProjetosSelecionados([]);
        setClienteSelecionado('');
        setCategoriesSelect('');
        setTodosProjetosCategorias(false);
    };

    const optionsProjetos = [
        { value: "TODOS", label: 'TODOS OS PROJETOS' },
        ...projetoFiltradoCategoria
            .slice()
            .sort((a, b) => a.key.localeCompare(b.key))
            .map(item => ({
                value: item.key,
                label: `${item.key} - ${item.name} - ${item.category}`,
                categoria: item.category
            }))
    ];

    const adicionarCategoria = (projetosProps) => {
        const categorias = {};
        projetosProps.forEach(item => {
            categorias[item.key] = item.category;
        });
        setCategoriasChave(categorias);
    };     

    return(
        <>
        <span style={{fontSize: "12px"}}>* Campo obrigatório</span>
        <Form className="mt-3">
            <div className='d-flex'>
            <Form.Group className='d-flex flex-column mr-3'>
                <Form.Label>Período inicial: </Form.Label>
                <MonthYearPicker setMonthYear={setInitialDate} monthYear={initialDate}/>
            </Form.Group>
            <Form.Group className='d-flex flex-column mr-3'>
                <Form.Label>Período final: </Form.Label>
                <MonthYearPicker setMonthYear={setFinalDate} monthYear={finalDate}/>
            </Form.Group>
            </div>

            <Form.Group>
                <Form.Label>Filtrar por: *</Form.Label>
                <Form.Check
                    type="radio"
                    label="Cliente"
                    name="radioGroup"
                    id="por-cliente"
                    value="cliente"
                    checked={selectedOptionRadio === 'cliente'}
                    onChange={handleRadioChange}
                />
                <Form.Check
                    type="radio"
                    label="Projeto"
                    name="radioGroup"
                    id="por-projeto"
                    value="projeto"
                    checked={selectedOptionRadio === 'projeto'}
                    onChange={handleRadioChange}
                />
            </Form.Group>
            {selectedOptionRadio == "cliente" ? 
            <>
            <Form.Group>
                <Form.Label>Selecione o cliente: *</Form.Label>
                <Form.Control
                    as="select"
                    name="clientes"
                    onChange={handleClienteChange}
                >
                <option>--Selecione o cliente--</option>
                {clientes
                    .slice()
                    .sort((a, b) => a.nome.localeCompare(b.nome))
                    .map((item, index) => (
                        <option value={item.nome} key={index}>{item.nome}</option>
                    ))
                }
                </Form.Control>
            </Form.Group>
            {clienteSelecionado && (
                <>
                <Form.Group>
                    <Form.Label>Selecione as categorias:</Form.Label>
                    <Select
                        components={animatedComponents}
                        isMulti
                        options={categories.map(categoria => ({
                            value: categoria.name,
                            label: categoria.name,
                            id: categoria.id,
                        }))}
                        value={categoriesSelect}
                        onChange={(e) => {handleCategoriaChange(e)}} 
                        placeholder="Selecione uma categoria"
                        closeMenuOnSelect={true}
                    />
                   
                </Form.Group>          
                <Form.Group>
                    <Form.Label>Selecione os projetos do cliente: *</Form.Label>
                        <Select
                            components={animatedComponents}
                            isMulti
                            options={selectedOptionRadio === 'cliente' ? memoizedProjetosClienteOptions : memoizedProjetosOptions}
                            value={todosProjetosCategorias ? [{ value: "TODOS", label: 'TODOS OS PROJETOS' }] : projetosSelecionados}
                            onChange={(e)=>{handleProjetosClienteChange(e)}}
                            placeholder="Selecione os projetos"
                            closeMenuOnSelect={true}
                        />
                </Form.Group>
                </>
            )}
            </>
            : selectedOptionRadio == "projeto" ?
            <>
                <Form.Group>
                <Form.Label>Selecione as categorias:</Form.Label>
                    <Select
                        components={animatedComponents}
                        isMulti
                        options={categories.map(categoria => ({
                            value: categoria.name,
                            label: categoria.name,
                            id: categoria.id,
                        }))}
                        value={categoriesSelect}
                        onChange={(e) => {handleCategoriaChange(e)}} 
                        placeholder="Selecione uma categoria"
                        closeMenuOnSelect={true}
                    />
                </Form.Group>
                <Form.Group>
                <Form.Label>Selecione os projetos: *</Form.Label>
                    <Select
                        components={animatedComponents}
                        isMulti
                        options={optionsProjetos}
                        value={todosProjetosCategorias ? [{ value: "TODOS", label: 'TODOS OS PROJETOS' }] : projetosSelecionados}
                        onChange={(e) => {handleProjetosChange(e)}}
                        placeholder="Selecione os projetos"
                        closeMenuOnSelect={true}
                    />
                </Form.Group>
            </>
            : <></>}
            
            <Form.Group>
                <Form.Label>Selecione o tipo de custo: *</Form.Label>
                <Form.Control
                    as="select"
                    name="tipoCusto"
                    value={tipoCusto}
                    onChange={(e) => {setTipoCusto(e.target.value)}}
                    >
                    <option value="">--Selecionar Custo--</option>
                    <option value="INFRA_CLOUD">Infraestrutura em Cloud</option>
                    <option value="OUTROS_SOFTWARES">Outros softwares</option>
                    <option value="HORAS_EXTRAS">Horas extras</option>
                    <option value="FORNECEDORES_TERCEIROS">Fornecedores terceiros</option>
                    <option value="OUTROS">Outros</option>
                    <option value="VALOR_HH">Valor HH</option>
                    <option value="total">Total</option>
                </Form.Control>
            </Form.Group>
        </Form>
        <Button onClick={() => handleDadosProjetosHistorico(projetosSelecionados, selectedOptionRadio, initialDate, finalDate, tipoCusto, clienteSelecionado)}>Gerar</Button>
        </>
    )
}

export default FiltroHistoricoCusto;