import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FaRegCalendar, FaRegCalendarCheck } from 'react-icons/fa';
import { Alert, Button, Form } from 'react-bootstrap';
import { useCheckboxState, Checkbox } from "reakit/Checkbox";

import PageContainer from '../../../components/PageContainer';
import ListLayout from '../../../components/ListLayout';
import { columns } from './ProjetosListInfo';
import Loading from '../../../components/Loading';

import ErrorRequestService from '../../../services/errorRequest';
import ProjetosApiService from '../../../services/api/ProjetosApiService';
import UtilService from '../../../services/util';
import { ProjetosListHelpeModal } from './ProjetosListHelpeModal';
import CustomPagination from '../../../components/CustomPagination';
import CustomModal from '../../../components/CustomModal';
import ClienteoApiService from '../../../services/api/CadastroClientesService';
import useAlertNotification from '../../../context/AlertNotificationContext';

const INITIAL_PAGEPARAMS = {
    orderBy: 'cod',
    orderDir: 'asc'
};

const ORDER_DIR_NUM = { asc: 1, desc: -1 };

function ProjetoslList(props) {
    // CONTEXT
    const match = useRouteMatch();
    const history = useHistory();
    const alert = useAlertNotification();

    // STATE
    const [rowsData, setRowsData] = useState(null);
    const [filteredData, setFilteredData] = useState(null);
    const [filteringField, setFilteringField] = useState(null); // campo de filtragem
    const [orderBy, setOrderBy] = useState(INITIAL_PAGEPARAMS.orderBy);
    const [orderDir, setOrderDir] = useState(INITIAL_PAGEPARAMS.orderDir);
    const [isLoading, setIsLoading] = useState(true);
    const [isGettingExportData, setIsGettingExportData] = useState(false);
    const [itemsPage, setItemsPage] = useState([]);
    const [rowsDataCategorias, setRowsDataCategorias] = useState([]);
    const [rowsDataChecked, setRowsDataChecked] = useState([]);
    const [verificarRow, setVerificarRow] = useState(false);
    const [isFilteredBool, setIsFilteredBool] = useState(false)

    const [todosOsProjetos, setTodosOsProjetos] = useState([]);
    const [todosLideres, setTodosLideres] = useState([]);
    const [todosClientes, setTodosClientes] = useState([]);


    const [allProjectChecked, setAllProjectChecked] = useState(false);
    const checkbox = useCheckboxState({ state: [] });


    // FUNCTIONS
    /** Define o clique sobre um registro da lista */
    // const rowClick = (item) => {
    //     history.push(`${match.path}/${item.key}`, item);
    // }

    /** Adiciona click e atributos ao header */
    const getListHeader = () => {
        const headers = [];
        const defineOrder = (item) => {
            if (orderBy === item) {
                const newDir = (orderDir === 'asc') ? 'desc' : 'asc';
                setOrderDir(newDir);
                return;
            }
            setOrderBy(item);
        }
        columns.forEach(item => {
            const waySign = (ORDER_DIR_NUM[orderDir] > 0) ? '▴' : '▾';
            const orderInd = (orderBy === item.name)
                ? {ordered: `${waySign}`}
                : {};
            let head = {
                ...item,
                ...orderInd,
                onClick: () => defineOrder(item.name),
            }
            if (item.name === 'checkbox') {
                head = {
                    ...item,
                    ...orderInd,
                    label: <Checkbox checked={allProjectChecked} />,
                    onClick: (e) => selectAllProjects(),
                }
            }
            headers.push(head);
        })
        return headers;
    }

    const dataChecked = (item) => {
        let dataChecked = rowsDataChecked.filter((data) =>  data.name === item.name);
        if(dataChecked.length > 0) {
            setRowsDataChecked([...rowsDataChecked])
        } else {
            setRowsDataChecked([...rowsDataChecked, item])
        }
    }

    useEffect(() => {setRowsDataChecked(rowsDataChecked.filter(data => checkbox.state.some(item => data.key == item)))}, [checkbox.state])

    /** Adequa os dados ao formato para lista */
    const defineDataList = () => {
        const sortRows = (a, b) => {
            if (a[orderBy] < b[orderBy]) return (-1 * ORDER_DIR_NUM[orderDir]);
            if (a[orderBy] > b[orderBy]) return (1 * ORDER_DIR_NUM[orderDir]);
        }
        const dataList = [];
        const rows = !verificarRow ? filteredData || [] : rowsData;

        rows.sort(sortRows)
            .forEach(item => {
                const row = {
                    // onClick: () => rowClick(item),
                    data: columns.map(col => {
                        if (col.name === 'checkbox') {
                            return {
                                value: <Checkbox {...checkbox} value={item.key}  onClick={(e) => {
                                    const checked = e.target.getAttribute('aria-checked');
                                    if (checked === "false") dataChecked(item);
                                    else setRowsDataChecked(rowsDataChecked.filter(data => data !== item));
                                }} />
                            }
                        }

                        // ---------------- cor/icone bloqueio ----------------
                        if (col.name === 'bloqueio') {
                            return {
                                value: (item[col.name] ? <FaRegCalendarCheck /> : <FaRegCalendar />),
                                className: col.dynamicClass && col.dynamicClass(item[col.name]),
                                attr: {
                                    title: `${item[col.name] ? '' : 'Não '}Bloqueado`,
                                    'data-toggle': 'tooltip',
                                }
                            }
                        }
                        // ---------------- formato date dd/mm/yyyy ----------------
                        if (['dataInicio', 'dataFim'].includes(col.name)) {
                            return {
                                value: UtilService.apiDateToView(item[col.name]),
                                className: col.className
                            }
                        }

                        if (col.name === 'lastIssueUpdateTime') {
                            return {
                                value: item[col.name] && UtilService.apiDateToView(item[col.name]),
                                className: col.className,
                            }
                        }

                        return {
                            value: item[col.name],
                            className: col.className
                        }
                    })
                }
                dataList.push(row);
            });
        return dataList;
    }

    /* Filtra a lista de dados */
    const filterList = async (params, startAt = 0) => {
        const { column, filter } = params;
        setIsLoading(true);
        setVerificarRow(false)
        setIsFilteredBool(false)

        if (column === 'checkbox') {
            const filtered = rowsDataChecked.filter(item => item !== null);

            setItemsPage(0);
            setFilteringField(params);
            setIsLoading(false);
            setAllProjectChecked(true);
            return setFilteredData(filtered);
        }

        if (column !== 'checkbox' && filter !== '') {
            try {
                if (isNaN(startAt)) return;

                let resp = {}
                if (column === 'category') {
                    resp = (await ProjetosApiService.filterPaginatedByCategory(startAt, filter)).data;
                } else {
                    resp = (await ProjetosApiService.filterPaginated(startAt, filter)).data;
                }

                setItemsPage(resp.pages - 1);

                const filterData = resp.data;

                const notDuplicateData = notDuplicate(filterData)

                setRowsData(notDuplicateData);
                setFilteredData(notDuplicateData);

                checkDataChecked(notDuplicateData);
            }
            catch (err) {
                const errorService = new ErrorRequestService(err);
                const errorMsg = errorService.getErrors();
                console.log(errorMsg);
                alert(errorMsg);
            }
            finally {
                setIsLoading(false);
                setFilteringField(params);
            }
            return
        }

        getDataList();
    }

    // Verifica se todos projetos da pagina estão marcados ou não
    const checkDataChecked = (arrCheck) => {
        const keysDataChecked = rowsDataChecked.map(item => item.key);
        const dataIncludesDataChecked = arrCheck.every(item => keysDataChecked.includes(item.key));

        dataIncludesDataChecked ? setAllProjectChecked(true) : setAllProjectChecked(false);
    }


    const notDuplicate = (arr) => arr.reduce((accum, curr) => {
        if (accum.length <= 0) return [curr];

        const notSame = accum.filter(item => item.key !== curr.key);
        return [...notSame, curr];
    }, []);

    // Selecionar e deselecionar todos projetos da pagina atual
    const selectAllProjects = () => {
        setAllProjectChecked(!allProjectChecked)
        const auxFilteredData = verificarRow ? rowsData : filteredData;
        
        const newDataChecked = notDuplicate([...rowsDataChecked, ...auxFilteredData])

        if (!allProjectChecked) {
            checkbox.setState(newDataChecked.map(item => item.key))
            setRowsDataChecked(newDataChecked)
        } else {
            const newArr = newDataChecked.filter(item => !auxFilteredData.includes(item))
            setRowsDataChecked(newArr)
            checkbox.setState(newArr.map(item => item.key))
        }
    }

    /** Traz as funcoes cadastradas no database  */
    const getDataList = useCallback(async (startAt = 0) => {
        setIsLoading(true);

        try {
            if (isNaN(startAt)) return;
            const resp = (await ProjetosApiService.getAllPaginated(startAt)).data;
            const respCategorias = (await ProjetosApiService.getCategories()).data;

            setRowsDataCategorias(respCategorias.data);

            setItemsPage(resp.pages - 1);
            setRowsData(resp.data);
            setFilteredData(resp.data);

            checkDataChecked(resp.data);
        }
        catch (err) {
            const errorService = new ErrorRequestService(err);
            const errorMsg = errorService.getErrors();
            console.log(errorMsg);
            alert(errorMsg);
        }
        finally {
            setIsLoading(false);
            setFilteringField(null);
        }
    }, []);

    /** Verifica se os dados está filtrado e vai para a próxima página */
    const getNextPaginationData = async (page) => {
        const paginationPage = page;
        return filteringField ? filterList(filteringField, paginationPage) : getDataList(paginationPage);
    }

    const exportAllListInExcel = async () => {
        setIsGettingExportData(true);
        const header = [['Key', 'Nome', 'Categoria', 'Líder', 'Última atualização']];
        let data = [];

        if(rowsDataChecked.length > 0) {
            data = rowsDataChecked
        } else {
            data = rowsData
        }

        const dataExcel = data.map((item) => {
            const date = new Date(item.lastIssueUpdateTime);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();

            return {
                key: item.key,
                nome: item.name,
                categoria: item.category,
                lider: item.lead,
                ultimaAtualizacao: `${day}/${month}/${year}`
            }
        })

        UtilService.exportXls(dataExcel, title, header);
        setIsGettingExportData(false);
    }

    const handleCarregarProjetos = async ()=>{
        try {
            const arrayDeProjetos = [];
            const uniqueLeadsSet = new Set();
            const respostaInicial = await ProjetosApiService.getAllPaginated(0);
            arrayDeProjetos.push(...respostaInicial.data.data);

            const totalPages = respostaInicial.data.pages;
            const requests = [];

            for (let i = 1; i < totalPages; i++) {
                requests.push(ProjetosApiService.getAllPaginated(i));
            }

            const responses = await Promise.all(requests);
            responses.forEach(response => {
                arrayDeProjetos.push(...response.data.data);
                response.data.data.forEach(projeto => uniqueLeadsSet.add(projeto.lead));
            });

            const responseClient = await ClienteoApiService.getClientAll();

            if(responseClient.status != 200) {
                console.error("Erro ao recuperar todos os clientes!")
            }

            setTodosClientes(responseClient.data)
            setTodosOsProjetos(arrayDeProjetos);
            setTodosLideres([...uniqueLeadsSet]);

        }catch(err){
            console.error("Erro ao recuperar todos os projetos: ", err)
        }
    }

    // EFFECTS
    useEffect(() => {
        if (!rowsData) getDataList();
    }, [rowsData, getDataList]);

    useEffect(() => {
        setRowsData(rowsData)
    }, [rowsData])

    useEffect(()=>{
        handleCarregarProjetos();
    },[])

    const pageActions = [

    ]

    const title = 'Lista de Projetos';

    return (
        <PageContainer
            title={title}
            actions={pageActions}
            infoButton={<ProjetosListHelpeModal />}
        >
            {isLoading
                ? <Loading message="Carregando..." />
                :
                <>
                    <Button
                        disabled={isGettingExportData}
                        className="mr-3 mb-2"
                        onClick={exportAllListInExcel}>Exportar</Button>

                    <Button
                        className="mr-3 mb-2"
                        disabled={checkbox.state.length === 0}
                        onClick={() => {
                            // console.log(checkbox.state);
                            history.push(`${match.path}/${checkbox.state}`);
                        }}>
                        Resultados
                    </Button>

                    <Button
                        className="mr-3 mb-2"
                        disabled={checkbox.state.length !== 1}
                        onClick={() => {
                            history.push(`${match.path}/previsao/${checkbox.state}`);
                        }}>
                        Planejamento
                    </Button>

                    <Button
                        className="mr-3 mb-2"
                        disabled={checkbox.state.length <= 1}
                        onClick={() => {
                            history.push(`${match.path}/compare/${checkbox.state}`);
                        }}>
                        Comparar Projetos
                    </Button>

                    {rowsDataChecked.length > 0 && (
                        <Alert variant='info' className='d-flex'>
                            <b>{rowsDataChecked.length} </b> &nbsp;Projetos selecionados
                        </Alert>
                    )}

                    <ListLayout
                        getDataList={getDataList}
                        rowsDataCategorias={rowsDataCategorias}
                        todosOsProjetos={todosOsProjetos}
                        todosLideres={todosLideres}
                        todosClientes={todosClientes}
                        isFilteredBool={isFilteredBool}
                        setIsFilteredBool={setIsFilteredBool}
                        setIsLoading={setIsLoading}
                        setVerificarRow={setVerificarRow}
                        setRowsData={setRowsData}
                        setFilteredData={setFilteredData}
                        columns={getListHeader()}
                        rows={defineDataList()}
                        rowsDataChecked={rowsDataChecked}
                        setRowsDataChecked={setRowsDataChecked}
                        onSubmitFilter={filterList}
                        firstColumn={filteringField?.column ?? ''}
                        optionInitialFilter='name'
                        columnsNotFilter={['clients', 'category', 'lastIssueUpdateTime', 'lead']}
                    />
                </>
            }
            <CustomModal />
            {<CustomPagination verificarRow={verificarRow} isLoading={isLoading} totalPages={itemsPage} pageNeighbours={1} getNextPaginationData={getNextPaginationData} />}
            
        </PageContainer>
    )
}

export default ProjetoslList;
