import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import MonthYearPicker from "../../../../components/MonthYearPicker";
import 'react-datepicker/dist/react-datepicker.css';

const FiltroRelatorioCapacidade = ({ handleDadosRelatorioCapacidade, projetos }) => {

    //States
    const [initialDate, setInitialDate] = useState(new Date());
    const [finalDate, setFinalDate] = useState(new Date());

    return (       
        <> 
            <Form className="mt-3">
                <div className='d-flex'>
                <Form.Group className='d-flex flex-column mr-3'>
                    <Form.Label>Período inicial: </Form.Label>
                    <MonthYearPicker setMonthYear={setInitialDate} monthYear={initialDate}/>
                </Form.Group>
                <Form.Group className='d-flex flex-column mr-3'>
                    <Form.Label>Período final: </Form.Label>
                    <MonthYearPicker setMonthYear={setFinalDate} monthYear={finalDate}/>
                </Form.Group>
                </div>
            </Form>
            <Button onClick={() => handleDadosRelatorioCapacidade(initialDate, finalDate, projetos)}>Gerar</Button>
        </>
    )
    
}
export default FiltroRelatorioCapacidade