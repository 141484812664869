import React, { useCallback, useEffect, useState } from 'react';
import { Button, ButtonGroup, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { FaEraser, FaFilter, FaPlus } from 'react-icons/fa';

import './ListTopbar.css';

function ListTopbarCustom({ columnsListTopbar, optionInitialFilter, multiFilter, setFilterType }) {
    
    // STATE
    const [form, setForm] = useState({ column: optionInitialFilter || columnsListTopbar[0].name });
    const [multiFilterForm, setMultiFilterForm] = useState([]);
    const [isFiltered, setIsFiltered] = useState(false);
    const [infoMsg, setInfoMsg] = useState('');
    const [infoMultiFilterMsg, setInfoMultiFilterMsg] = useState('');

    // FUNCTIONS
    const getLabelColumn = () => {
        const found = columnsListTopbar.find(item => item.name === form.column);
        if (form.column === 'checkbox') return form.column;
        return found.label || form.column;
    }

    const formatMultMessage = (msg) => {
        const formatedMsg = msg.map(item => `${item.column}: ${item.filter}`).toString();
        return formatedMsg;
    }

    /* Filtra a lista de dados */
    const filterList = async (params) => {
        setFilterType(params)
    }

    const onSubmitForm = (ev) => {
        ev.preventDefault();
        setIsFiltered(true);
        const formFilter = form.filter === null || form.filter === undefined ? '' : `'${form.filter}'`;
        const msg = multiFilter ? formatMultMessage(multiFilterForm) : `Filtro aplicado ${formFilter} para '${getLabelColumn()}'`;
        setInfoMsg(msg);
        setInfoMultiFilterMsg('');

        const submitForm = multiFilter ? multiFilterForm : form;
        filterList(submitForm);
    }

    const onSelectDropdown = (evKey) => {
        setForm(({ ...form, column: evKey }));
    }

    const disabledSearch = (filterList === null);

    const onChangeInput = (ev) => {
        setForm(({ ...form, filter: ev.target.value }));
    }

    const notFilterWithEmptyField = () => {
        const filterName = getLabelColumn();

        if (multiFilter && multiFilterForm.length > 0) return false;

        if (filterName === 'checkbox')
            return false;
        return !form.filter;
    }

    const notInput = notFilterWithEmptyField();

    const onAddMoreFilter = () => {
        setIsFiltered(false);
        const multFilter = [...multiFilterForm, form];
        setMultiFilterForm(multFilter);

        const msg = formatMultMessage(multFilter);
        setInfoMultiFilterMsg(`Aplicando filtro para ${msg}`);

        const clearFilterForm = { ...form, filter: '' };
        setForm(clearFilterForm);
    }

    return (
            <div className="list-topbar">
                <div className="filter">

                    <Form
                        className="filter-form"
                        onSubmit={onSubmitForm}
                    >
                        <Dropdown onSelect={onSelectDropdown}>
                            <Dropdown.Toggle
                                variant="light"
                                id="dd-column"
                                disabled={disabledSearch}
                                value={form.column}
                                defaultValue={form.column}
                                name="column"
                            >
                                Filtrar por: <span style={{ fontWeight: 'bold' }}>
                                    {form.column
                                        ? getLabelColumn()
                                        : columnsListTopbar[0].label
                                    }
                                </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {columnsListTopbar.map((item, idx) => {
                                    const key = `dd-item-${idx}`;
                                    return (
                                        <Dropdown.Item
                                            key={key}
                                            eventKey={item.name}
                                        >
                                            {item.labelAlternative ? item.name : item.label}
                                        </Dropdown.Item>
                                    )
                                })}
                            </Dropdown.Menu>
                        </Dropdown>

                        <Form.Control
                            placeholder="Filtro"
                            className="filter-input"
                            disabled={disabledSearch}
                            onChange={onChangeInput}
                            value={(form.filter || '')}
                            name="filter"
                            autoComplete="off"
                            type="text"
                        />
                        {multiFilter && (
                            <Button
                                size="sm"
                                variant="outline-secondary"
                                type="button"
                                disabled={notInput}
                                onClick={onAddMoreFilter}
                            >
                                <FaPlus />
                            </Button>
                        )}
                        <Button
                            size="sm"
                            variant="outline-secondary"
                            type="submit"
                            disabled={disabledSearch || notInput}
                        >
                            <FaFilter />
                        </Button>
                    </Form>
                </div>
            </div>
        )

}

ListTopbarCustom.defaultProps = {
    columns: [],
    actions: [],
    onSubmitFilter: null,
    firstColumn: ''
}

export default ListTopbarCustom;